import {
  CalendarOutlined,
  FieldNumberOutlined,
  UserOutlined,
  LineChartOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  notification,
  Row,
  Spin,
  Timeline,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  setPadLandingsList,
  uploadPadLandings,
} from '../../store/actions/padLandingsActions';
import api from '../../utils/appApi';
import ApproveButton from './ApproveButton';

const LandingsList = () => {
  const dispatch = useDispatch();
  const { pad_id } = useParams();
  const { landingList, toggle, padQuickAccess } = useSelector((store) => ({
    landingList: store.padLandings?.payload.landingList,
    toggle: store.padLandings?.payload.toggle,
    padQuickAccess: store.padQuickAccess?.payload,
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreBtnStatus, setShowMoreBtnStatus] = useState(true);
  const [showMoreLoading, setShowMoreLoading] = useState(false);

  useEffect(() => {
    if (!toggle || !padQuickAccess?.pad?.is_owned_by_current_user) return;
    setIsLoading(true);
    api.landings
      .get(pad_id, { page: currentPage })
      .then((res) => {
        if (currentPage === 1) {
          setPadLandingsList(res.data.data)(dispatch);
        } else if (currentPage > 1) {
          uploadPadLandings(res.data.data)(dispatch);
        }
        if (res.data.data.length < res.data.pagination.per_page)
          setShowMoreBtnStatus(false);
        if (res.data.data.length >= res.data.pagination.per_page)
          setShowMoreBtnStatus(true);
        if (res.data.pagination.last_page <= 1) setShowMoreBtnStatus(false);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'Something went wrong. Please try again later.',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, pad_id, toggle, dispatch, padQuickAccess]);

  useEffect(() => {
    if (!padQuickAccess?.pad?.is_owned_by_current_user) {
      setIsLoading(false);
    };
  }, [padQuickAccess]);

  useEffect(() => {
    if (!toggle) {
      setPadLandingsList([])(dispatch);
      setCurrentPage(1);
      setShowMoreBtnStatus(true);
      setShowMoreLoading(false);
    }
  }, [toggle, dispatch]);

  const reviewsShowMoreHandler = () => {
    setCurrentPage((prevState) => prevState + 1);
    setShowMoreLoading(true);
  };

    if (!toggle || !padQuickAccess?.pad?.is_owned_by_current_user) return (
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            description={
                <span>
          No enabled landings
        </span>
            }
        />
    );

  if (isLoading && !landingList?.length) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center">
          <Spin />
        </div>
      </div>
    );
  }

  if (!landingList?.length) {
    return <Empty />;
  }

  // Extend dayjs with plugins
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return (
    <div>
      <Timeline>
        {landingList.map((item, index) => (
          <Timeline.Item key={index}>
            <div className="timeline-card">
              <div className="d-flex flex-column">
                <Typography.Text>
                  <CalendarOutlined className="mr-1" />
                  <b>Landing:</b>{' '}
                </Typography.Text>
                <Typography.Text>
                  {dayjs
                    .utc(item.date_time)
                    .local()
                    .format('DD MMM YYYY HH:mm')}
                </Typography.Text>
              </div>
              <div className="d-flex flex-column">
                <Typography.Text>
                  <FieldNumberOutlined className="mr-1" />
                  <b>Tail number:</b>{' '}
                </Typography.Text>
                <Typography.Text>{item.tail_number}</Typography.Text>
              </div>
              <div className="d-flex flex-column">
                <Typography.Text>
                  <UserOutlined className="mr-1" />
                  <b>Pilot:</b>{' '}
                </Typography.Text>
                <Typography.Text className="name text-color-primary">
                  {item?.user?.name}
                </Typography.Text>
              </div>
              <div className="d-flex flex-column">
                <Typography.Text>
                  <LineChartOutlined className="mr-1" />
                  <b>Experience:</b>{' '}
                </Typography.Text>
                <Typography.Text>{item?.user?.experience}</Typography.Text>
              </div>
              <div
                className={`d-flex flex-column ${
                  item?.can_be_approved && 'button-row'
                }`}
              >
                {item?.can_be_approved ? (
                  <ApproveButton landingId={item.id}/>
                ) : (
                  <>
                    <Typography.Text>
                      <PlaySquareOutlined className="mr-1" />
                      <b>Action:</b>{' '}
                    </Typography.Text>
                    <Typography.Text className="text-color-primary">
                      {item?.status}
                    </Typography.Text>
                  </>
                )}
              </div>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
      {showMoreBtnStatus && (
        <Row>
          <Col span={24} className="text-center">
            <Button
              className="outline-btn"
              onClick={reviewsShowMoreHandler}
              loading={showMoreLoading}
            >
              Show More
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LandingsList;
